import React from "react"
import { Helmet } from "react-helmet"

import "./style.css"

import favicon16 from "./img/favicon-16x16.png"
import favicon32 from "./img/favicon-32x32.png"
import faviconApple from "./img/favicon-apple-touch.png"

export default ({ children }) => <div id="container">
  <Helmet>
    <meta charSet="utf-8" />
    <title>Dan Nuttall — Software Engineer</title>
    <link rel="canonical" href="https://nuttall.dev" />

    <link rel="apple-touch-icon" sizes="180x180" href={faviconApple} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
  </Helmet>

  {children}
</div>
